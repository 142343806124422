@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  max-width: 100% !important;
}

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  max-width: 100% !important;
}

.main {
  height: 100vh;
  width: 100vw;
  position: relative;
  padding: 0;
  margin: 0;
}

.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-container input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.checkmark {
  width: 22px;
  height: 22px;
  border: 2px solid #aaa;
  border-radius: 3px;
  margin-right: 6px;
  display: inline-block;
  position: relative;
}

.checkbox-container input[type="checkbox"]:checked~.checkmark {
  background-color: #2196F3;
}

.checkbox-container .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input[type="checkbox"]:checked~.checkmark::after {
  display: block;
}

.checkbox-container .checkmark::after {
  left: 6px;
  top: 2px;
  width: 4px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.radio-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 16px;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.dot {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #e6e6e6;
  border-radius: 50%;
}

.radio-container:hover input~.dot {
  background-color: #ccc;
}

.radio-container input:checked~.dot {
  background-color: #2196F3;
}

.dot:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-container input:checked~.dot:after {
  display: block;
}

.radio-container .dot:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.nav-body {
  height: 100%;
  width: 100vw;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bacasime+Antique&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700;800&display=swap');







.remove-app {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-border {
  border: 0;
}

.no-bg {
  background-color: rgba(0, 0, 0, 0);
}

.all-caps {
  text-transform: uppercase;
}

.light {
  background-color: white;
  color: black;
}

.dark {
  background-color: black;
  color: white;
}


.scroll {
  overflow-y: scroll;
}

.auto {
  width: 100%;
  height: 100%;
}

.padding {
  padding: 1em;
}

.padding-v {
  padding-bottom: 1em;
  padding-top: 1em;
}

.padding-h {
  padding-left: 1em;
  padding-right: 1em;
}

.horizontal {
  display: flex;
  justify-content: initial;
}

.vertical {
  display: flex;
  flex-direction: column;
}

.separate {
  display: flex;
  justify-content: space-between;
}

.align-top {
  display: flex;
  justify-content: flex-start;
}

.align-bottom {
  display: flex;
  justify-content: flex-end;
}

.h-space-around {
  align-content: space-around;
}

.v-space-around {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.gap {
  gap: 1em;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100vh;
}

.center {
  margin: 0 auto;
}

.left {
  float: left;
}

.right {
  float: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.h-center {
  align-items: center;
}

.v-separate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.fit-height {
  height: fit-content;
}

.fit-weight {
  width: fit-content;
}

.fill {
  object-fit: cover;
}

.fit {
  object-fit: contain;
}

.image-top {
  object-position: top;
}

.image-center {
  object-position: center;
}

.image-bottom {
  object-position: bottom;
}

.no-wrap {
  white-space: nowrap;
}



.roboto {
  font-family: 'Roboto', sans-serif;
}

.raleway {
  font-family: 'Raleway', sans-serif;
}

.roboto-cond {
  font-family: 'Roboto Condensed', sans-serif;
}

.rubik {
  font-family: 'Rubik', sans-serif;
}

.bacasime {
  font-family: 'Bacasime Antique', serif;
}

.libre {
  font-family: 'Libre Baskerville', serif;
}

.nanum {
  font-family: 'Nanum Myeongjo', serif;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
}

.underline {
  border-bottom: 1px solid black;
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0.8);
}

.border-right-light {
  border-right: 1px solid black;
}

.border-left-light {
  border-left: 1px solid black;
}

.border-right-dark {
  border-right: 1px solid rgb(249, 249, 249);
}

.border-right-dark {
  border-left: 1px solid rgb(249, 249, 249);
}



@media only screen and (min-width: 350px) {}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 800px) {}

@media only screen and (min-width: 1000px) {}

@media only screen and (min-width: 1200px) {}